import useCollapse from 'react-collapsed';
import { Controller } from 'react-hook-form';
import { Button, Checkbox, Dropdown, Input, InputCard, RadioGroup, Spinner, Text } from 'src/components';
import { Modal } from 'src/components/atoms/Modal/Modal';
import { BackButton } from 'src/components/molecules/BackButton/BackButton';
import { IconFactory } from 'src/factories/icon.factory';
import { QuoterPaymentFormFacade } from 'src/modules/quoter/controller/facades/quoter-payment-form.facade';
import { QuoterPaymentFacade } from 'src/modules/quoter/controller/facades/quoter-payment.facade';
import { QuoterFacade } from 'src/modules/quoter/controller/facades/quoter.facade';
import { PAYMENT_ROUTE_ID } from 'src/routes/paths/quoter.paths';
import { Util } from 'src/utils/Util';
import QuoterContent from '../../components/QuoterContent/QuoterContent';
import './QuoterPayment.scss';

/**
 *
 */
const QuoterPaymentPage = (): JSX.Element => {

	const { storedPerson, storedPlan } = QuoterFacade.useQuoterActors();
	const {
		urlId,
		paymentMethod,
		paymentType,
		paymentCost,
		showRenewalModal,
		showPicturesModal,
		isAutomaticRenewal,
		renewalItems,
		isCardCompleted,
		isCompletingCheckout,
		onPaymentTypeChange,
		setCheckoutCard,
		setShowRenewalModal,
		setShowPicturesModal,
		setIsAutomaticRenewal,
		proceed,
		getTotal,
		getTotalByMonth,
		getNetAmount,
		getMonthFeeAmount,
		getSubTotal,
		getTaxes,
		onBackRoute,
		initCheckout
	} = QuoterPaymentFacade.useQuoterPayment();
	const desktopCollapse = useCollapse({ duration: 350, defaultExpanded: false, });
	const mobileCollapse = useCollapse({ duration: 350, defaultExpanded: false, });
	const { methodForm } = QuoterPaymentFormFacade.useQuoterPaymentMethodForm();
	const { paymentForm } = QuoterPaymentFormFacade.useQuoterPaymentForm();

	return (
		<div className="quoterPayment">
			<QuoterContent
				backButton={<BackButton to={onBackRoute()} />}
				title={`${storedPerson?.name}, confirma forma de pago.`}
			>
				{
					storedPlan &&
					<>
						<div className="quoterPayment__dataWrapper">

							<div className="quoterPayment__dataWrapper__inputsSection">

								{
									urlId === PAYMENT_ROUTE_ID.METHOD &&
									<>
										<Dropdown
											items={[
												{ id: QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id, value: QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.name, info: storedPlan.subscription.cost.getTotal() },
												{ id: QuoterPaymentFacade.PLAN_COST.ANNUAL.id, value: QuoterPaymentFacade.PLAN_COST.ANNUAL.name, info: storedPlan.cost.getTotal() },
												...storedPlan.monthly.costs.map(
													(cost, index) => {
														return {
															id: (index + 1) + QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id,
															value: `Pago a ${cost.monthsQuantity} meses (Solo tarjeta de crédito)`,
															info: cost.getAmountByMonth()
														}
													}
												)
											]}
											defaultValue={paymentType}
											cancelSearch
											placeholder="FORMA DE PAGO"
											disabled={!paymentMethod}
											onChange={(t) => { t && onPaymentTypeChange(t) }}
										/>
									</>
								}

								{
									urlId === PAYMENT_ROUTE_ID.DATA &&
									<>
										<form action="">
											<Controller
												name="owner"
												control={paymentForm.control}
												rules={{
													required: true,
													pattern: Util.PATTERN.NAMES,
													minLength: 2
												}}
												render={({ field }) => (
													<Input
														autoComplete="off"
														required
														label="NOMBRE DEL TITULAR"
														disabled={isCompletingCheckout}
														onChange={(e) => field.onChange(e.target.value)}
													//setCheckoutOwner(e.target.value)
													/>
												)}
											/>

										</form>

										<InputCard disabled={isCompletingCheckout} onComplete={setCheckoutCard} />
									</>
								}


								<div className="quoterPayment__dataWrapper__inputsSection__information">
									<Text type="label" align="center" className="quoterPayment__dataWrapper__inputsSection__information__title" bold block>¿Cómo funciona?</Text>

									{
										paymentType.id === QuoterPaymentFacade.PLAN_COST.ANNUAL.id ?
											<>
												<Text type="label" bold color="main" align="center">Es un solo pago válido por un año de póliza. </Text>
												<Text type="label" align="center">El monto total de la compra será restado a tu tarjeta. Al finalizar este período deberás renovar tu póliza con una nueva tarifa.</Text>
											</>
											:
											<>
												{
													paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id ?
														<>
															<Text type="label" bold color="main" align="center">Pagarás tu póliza mensualmente durante 12 meses. </Text>
															<Text type="label" align="center">Al finalizar este período deberás renovar tu póliza con una nueva tarifa.</Text>
														</>
														:
														<>
															<Text type="label" bold color="main" align="center">Pagarás tu póliza mensualmente durante {paymentCost.monthsQuantity} meses. </Text>
															<Text type="label" align="center">Al finalizar el año de cobertura deberás renovar tu póliza con una nueva tarifa.</Text>
														</>
												}
											</>
									}
									<Text type="label" className="quoterPayment__dataWrapper__inputsSection__information__text" align="center">&nbsp;Esta opción de pago quedará como predeterminada para tus próximos pagos.</Text>

									{
										paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id &&
										<>
											<Text type="label" align="center" className="quoterPayment__dataWrapper__inputsSection__information__title" block bold>
												¿Qu&eacute; se incluye en la 1ra cuota?
											</Text>

											<Text type="label" align="center" block>
												Primer pago de <b>{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getNetAmount() : storedPlan.subscription.firstCost.getNetAmount()}</b>
											</Text>
											<Text type="label" align="center" block>
												<b>+</b> Responsabilidad Civil <b>{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getCivilTaxAmount() : storedPlan.subscription.firstCost.getCivilTaxAmount()}</b>
											</Text>
											<Text type="label" align="center" block>
												<b>+</b> Tarifa <b>{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getMonthFeeAmount() : storedPlan.subscription.firstCost.getMonthFeeAmount()}</b>
											</Text>

											{
												paymentCost.expeditionRights && paymentCost.expeditionRights > 0 &&
												<Text type="label" align="center" block>
													<b>+</b> Costo de expedición* <b>{Util.TRANSFORM.CURRENCY.MXN(paymentCost.expeditionRights)}</b>
												</Text>
											}
											<Text type="label" align="center" block>
												<b>+</b> IVA <b>{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTaxes() : storedPlan.subscription.firstCost.getTaxes()}</b>
											</Text>
											<Text type="label" align="center" block bold>
												Total: {storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTotal() : storedPlan.subscription.firstCost.getTotal()}
											</Text>

											<Text type="label" align="center" className="quoterPayment__dataWrapper__inputsSection__information__title" block bold>
												¿Y los siguientes meses qu&eacute; costo tienen?
											</Text>
											<Text type="label" bold color="main" align="center">Durante los restantes 11 meses</Text>
											<Text type="label" align="center">
												, se realizar&aacute; un cobro a tu tarjeta por el monto de&nbsp;
												<b>{storedPlan.discount ? storedPlan.discount.subscription.cost.getTotal() : storedPlan.subscription.cost.getTotal()}</b>
											</Text>
										</>
									}
								</div>
							</div>

							<aside className="quoterPayment__dataWrapper__infoSection">

								{
									(paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id) &&
									<section className="quoterPayment__dataWrapper__infoSection__amounts">
										<div>
											<Text type="label" align="center" bold>Precio total <Text type="label" align="center" bold color="main">de la p&oacute;liza</Text></Text>
											<Text type="title" align="center" color="main" className="quoterPayment__dataWrapper__infoSection__amounts__bigAmount">
												{storedPlan.discount ? storedPlan.discount.subscription.getTotal() : storedPlan.subscription.getTotal()}
											</Text>
										</div>
									</section>
								}

								<section className="quoterPayment__dataWrapper__infoSection__amounts">
									{
										(paymentType.id === QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
										<div>
											<Text type="label" align="center">Total a pagar</Text>
											<Text type="title" align="center" color="main" className="quoterPayment__dataWrapper__infoSection__amounts__bigAmount">{paymentCost.getTotal()}</Text>
										</div>
									}

									{
										(paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id) &&
										<div>
											<Text type="label" align="center" bold>Pago inicial <Text type="label" align="center" bold color="main">1ra cuota</Text></Text>
											<Text type="title" align="center" color="main" className="quoterPayment__dataWrapper__infoSection__amounts__subscriptionAmount">
												{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTotal() : storedPlan.subscription.firstCost.getTotal()}
											</Text>
											<br />
											<Text type="label" align="center" bold>Por pagar <Text type="label" align="center" bold color="main">11 cuotas</Text></Text>
											<Text type="title" align="center" color="main" className="quoterPayment__dataWrapper__infoSection__amounts__subscriptionAmount">
												{storedPlan.discount ? storedPlan.discount.subscription.cost.getTotal() : storedPlan.subscription.cost.getTotal()}</Text>
										</div>
									}

									{
										(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id && paymentType.id !== QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id) &&
										<div className="quoterPayment__dataWrapper__infoSection__amounts__monthly">
											<Text type="label" align="center">{paymentCost.monthsQuantity} pagos de</Text>
											<Text type="title" align="center" color="main" className="quoterPayment__dataWrapper__infoSection__amounts__monthly__price">{paymentCost.getAmountByMonth() ? paymentCost.getAmountByMonth() : Util.TRANSFORM.CURRENCY.MXN(0)}</Text>
											<Text type="label" align="center">
												Total a pagar <span>{paymentCost.monthsQuantity > 1 ? paymentCost.getTotal() : Util.TRANSFORM.CURRENCY.MXN(0)}</span>
											</Text>
										</div>
									}

									<button className="quoterPayment__dataWrapper__infoSection__amounts__button" {...desktopCollapse.getToggleProps()} is-expanded={desktopCollapse.isExpanded ? '' : null}>
										<Text type="label" align="center" color="main" bold>Desglose del monto</Text>
										{IconFactory.downArrowIcon()}
									</button>
								</section>

								<section className="quoterPayment__dataWrapper__infoSection__summary" {...desktopCollapse.getCollapseProps()}>
									{
										paymentType.id !== QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id &&
										<>
											<Text className="quoterPayment__dataWrapper__infoSection__summary__title" bold type="label" align="center">Resumen de tu plan seleccionado</Text>

											<ul className="quoterPayment__dataWrapper__infoSection__summary__list">
												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Prima neta</Text>
													<Text type="label" align="right">
														{
															paymentCost.monthsQuantity <= 1 && (paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) && (paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code)
																? Util.TRANSFORM.CURRENCY.MXN(0)
																: getNetAmount()
														}
													</Text>
												</li>

												{
													<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
														<Text type="label" align="left">Costo de expedici&oacute;n*</Text>
														<Text type="label" align="right">
															{Util.TRANSFORM.CURRENCY.MXN(paymentCost.expeditionRights)}
														</Text>
													</li>
												}

												{
													(paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code || ((paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.DEBIT.code) && (paymentType.id === QuoterPaymentFacade.PLAN_COST.ANNUAL.id))) &&
													<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
														<Text type="label" align="left">Responsabilidad Civil</Text>

														<Text type="label" align="right">
															{
																(paymentCost.monthsQuantity <= 1) && (paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) && (paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.DEBIT.code)
																	? Util.TRANSFORM.CURRENCY.MXN(0)
																	: paymentCost.getCivilTaxAmount()
															}
														</Text>
													</li>
												}

												{
													(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
													<li className="quoterPayment__dataWrapper__infoSection__summary__list__monthlyItem">
														<div className="quoterPayment__dataWrapper__infoSection__summary__list__monthlyItem__amount">
															<Text type="label" align="left">
																Pago
																{(paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code) ? ' diferido* ' : ' mensual '}
																({paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code ? paymentCost.getFeePercent() : '20%'})
															</Text>
															<Text type="label" align="right">{getMonthFeeAmount()}</Text>
														</div>
													</li>
												}

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Subtotal</Text>
													<Text type="label" align="right">{getSubTotal()}</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">IVA (16%)</Text>
													<Text type="label" align="right">{getTaxes()}</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left" bold>Total</Text>
													<Text type="label" align="right" bold>{getTotal()}</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="center" className="quoterPayment__dataWrapper__infoSection__summary__list__monthlyItem__warning">* Este monto no será reembolsado <br /> en caso de cancelación anticipada.
													</Text>
												</li>
											</ul>
										</>
									}

									{
										paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id &&
										<>
											<Text className="quoterPayment__dataWrapper__infoSection__summary__title" bold type="label" align="center">Resumen del primer pago</Text>
											<ul className="quoterPayment__dataWrapper__infoSection__summary__list">
												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Prima neta</Text>
													<Text type="label" align="right">
														{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getNetAmount() : storedPlan.subscription.firstCost.getNetAmount()}
													</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Responsabilidad civil</Text>
													<Text type="label" align="right">
														{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getCivilTaxAmount() : storedPlan.subscription.firstCost.getCivilTaxAmount()}
													</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Pago diferido*</Text>
													<Text type="label" align="right">
														{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getMonthFeeAmount() : storedPlan.subscription.firstCost.getMonthFeeAmount()}
													</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Costo de expedición*</Text>
													<Text type="label" align="right">
														{
															storedPlan.discount ?
																Util.TRANSFORM.CURRENCY.MXN(storedPlan.discount.subscription.firstCost.expeditionRights)
																:
																Util.TRANSFORM.CURRENCY.MXN(storedPlan.subscription.firstCost.expeditionRights)
														}
													</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Subtotal</Text>
													<Text type="label" align="right">
														{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getSubTotal() : storedPlan.subscription.firstCost.getSubTotal()}
													</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">IVA</Text>
													<Text type="label" align="right">
														{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTaxes() : storedPlan.subscription.firstCost.getTaxes()}
													</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left" bold>Total</Text>
													<Text type="label" align="right" bold>
														{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTotal() : storedPlan.subscription.firstCost.getTotal()}
													</Text>
												</li>
											</ul>

											<Text className="quoterPayment__dataWrapper__infoSection__summary__title" bold type="label" align="center">Resumen de los siguientes 11 pagos</Text>
											<ul className="quoterPayment__dataWrapper__infoSection__summary__list">
												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Prima neta</Text>
													<Text type="label" align="right">
														{storedPlan.discount ? storedPlan.discount.subscription.cost.getNetAmount() : storedPlan.subscription.cost.getNetAmount()}
													</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Pago diferido*</Text>
													<Text type="label" align="right">
														{storedPlan.discount ? storedPlan.discount.subscription.cost.getMonthFeeAmount() : storedPlan.subscription.cost.getMonthFeeAmount()}
													</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">Subtotal</Text>
													<Text type="label" align="right">
														{storedPlan.discount ? storedPlan.discount.subscription.cost.getSubTotal() : storedPlan.subscription.cost.getSubTotal()}
													</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left">IVA</Text>
													<Text type="label" align="right">
														{storedPlan.discount ? storedPlan.discount.subscription.cost.getTaxes() : storedPlan.subscription.cost.getTaxes()}
													</Text>
												</li>

												<li className="quoterPayment__dataWrapper__infoSection__summary__list__listItem">
													<Text type="label" align="left" bold>Total</Text>
													<Text type="label" align="right" bold>
														{storedPlan.discount ? storedPlan.discount.subscription.cost.getTotal() : storedPlan.subscription.cost.getTotal()}
													</Text>
												</li>
											</ul>
										</>
									}
								</section>

								<section className="quoterPayment__dataWrapper__infoSection__bottomSection">
									<form>
										{
											urlId === PAYMENT_ROUTE_ID.METHOD &&
											<Controller
												name="acceptPaymentMethod"
												control={methodForm.control}
												rules={{ required: true }}
												render={({ field }) => (
													<Checkbox onChange={(e) => { field.onChange(e.target.checked) }} checked={methodForm.getValues('acceptPaymentMethod')}>
														<Text type="label" align="left">Estoy de acuerdo con la forma de pago seleccionada.</Text>
													</Checkbox>
												)}
											/>

										}

										{
											urlId === PAYMENT_ROUTE_ID.DATA &&
											<>
												<Controller
													name="acceptResourcesOwner"
													control={paymentForm.control}
													rules={{ required: true }}
													render={({ field }) => (
														<Checkbox onChange={(e) => field.onChange(e.target.checked)}>
															<Text type="label" align="left">Declaro que soy propietario de los recursos.</Text>
														</Checkbox>
													)}
												/>

												<Controller
													name="acceptDigitalCover"
													control={paymentForm.control}
													rules={{ required: true }}
													render={({ field }) => (
														<Checkbox onChange={(e) => field.onChange(e.target.checked)}>
															<Text type="label" align="left">Estoy de acuerdo con recibir mi carátula de póliza en formato digital .PDF *</Text>
														</Checkbox>
													)}
												/>
											</>
										}
									</form>

									<Button type="button"
										disabled={
											(urlId === PAYMENT_ROUTE_ID.METHOD && !(methodForm.formState.isDirty && methodForm.formState.isValid))
											|| (urlId === PAYMENT_ROUTE_ID.DATA && (!(paymentForm.formState.isDirty && paymentForm.formState.isValid) || !isCardCompleted || isCompletingCheckout))
										}
										loading={isCompletingCheckout}
										onClick={proceed}
									>
										{urlId === PAYMENT_ROUTE_ID.METHOD ? 'Continuar' : 'Pagar'}
									</Button>
								</section>
							</aside>
						</div>

						{
							urlId === PAYMENT_ROUTE_ID.DATA &&
							<Text className="quoterPayment__dataWrapper__mobileCoverText" type="label" align="center">*Si no está de acuerdo con recibir la carátula en formato digital, póngase en contacto con nuestro <b>centro de atención.</b></Text>
						}
					</>
				}
			</QuoterContent>

			{
				urlId === PAYMENT_ROUTE_ID.DATA &&
				<Text className="quoterPayment__dataWrapper__coverText" type="label" align="center">*Si no está de acuerdo con recibir la carátula en formato digital, póngase en contacto con nuestro <b>centro de atención.</b></Text>
			}
			<aside className="quoterPayment__infoSection quoterPayment__mobileInfoSection">
				<section className="quoterPayment__mobileInfoSection__amounts">
					{
						(paymentType.id === QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
						<div>
							<Text type="label" align="center">Total a pagar</Text>
							<Text type="title" align="center" color="main" className="quoterPayment__mobileInfoSection__amounts__bigAmount">{paymentCost.getTotal()}</Text>
						</div>
					}

					{
						(paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id) &&
						<div>
							{/* <Text type="label" align="center">
									1er Pago (1er mes de {storedPlan.discount ? storedPlan.discount.subscription.firstCost.getNetAmount() : storedPlan.subscription.firstCost.getNetAmount()} <br/> + Responsabilidad Civil {storedPlan.discount ? storedPlan.discount.subscription.firstCost.getCivilTaxAmount() : storedPlan.subscription.firstCost.getCivilTaxAmount()}
									<br/> + Tarifa {storedPlan.discount ? storedPlan.discount.subscription.firstCost.getMonthFeeAmount() : storedPlan.subscription.firstCost.getMonthFeeAmount()} { paymentCost.expeditionRights && paymentCost.expeditionRights > 0 ? <><br/> + *Costo de expedición { Util.TRANSFORM.CURRENCY.MXN(paymentCost.expeditionRights) }</> : '' } + IVA {storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTaxes() : storedPlan.subscription.firstCost.getTaxes()})
								</Text> */}

							<Text type="title" align="center" color="main" className="quoterPayment__mobileInfoSection__amounts__subscriptionAmount">{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTotal() : storedPlan.subscription.firstCost.getTotal()}</Text>
							<Text type="label" align="center">Pago inicial 1ra cuota</Text>

							<Text type="title" align="center" color="main" className="quoterPayment__mobileInfoSection__amounts__subscriptionAmount">{storedPlan.discount ? storedPlan.discount.subscription.cost.getTotal() : storedPlan.subscription.cost.getTotal()}</Text>
							<Text type="label" align="center">Por pagar 11 cuotas</Text>
						</div>
					}

					{
						(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id && paymentType.id !== QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id) &&
						<div className="quoterPayment__mobileInfoSection__amounts__monthly">
							<Text type="label" align="center">{paymentCost.monthsQuantity} pagos de</Text>
							<Text type="title" align="center" color="main" className="quoterPayment__mobileInfoSection__amounts__monthly__price">{paymentCost.getAmountByMonth() ? paymentCost.getAmountByMonth() : Util.TRANSFORM.CURRENCY.MXN(0)}</Text>
							<Text type="label" align="center">
								Total a pagar <span>{paymentCost.monthsQuantity > 1 ? paymentCost.getTotal() : Util.TRANSFORM.CURRENCY.MXN(0)}</span>
							</Text>
						</div>
					}

					<button className="quoterPayment__mobileInfoSection__amounts__button" {...mobileCollapse.getToggleProps()} is-expanded={mobileCollapse.isExpanded ? '' : null}>
						<Text type="label" align="center" color="main" bold>Desglose del monto</Text>
						{IconFactory.downArrowIcon()}
					</button>
				</section>

				<section className="quoterPayment__mobileInfoSection__summary" {...mobileCollapse.getCollapseProps()}>
					{
						paymentType.id !== QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id &&
						<>
							<Text className="quoterPayment__mobileInfoSection__summary__title" bold type="label" align="center">Resumen de tu plan seleccionado</Text>

							<ul className="quoterPayment__mobileInfoSection__summary__list">
								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Prima neta</Text>
									<Text type="label" align="right">
										{
											paymentCost.monthsQuantity <= 1 && (paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) && (paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code)
												? Util.TRANSFORM.CURRENCY.MXN(0)
												: getNetAmount()
										}
									</Text>
								</li>

								{
									<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
										<Text type="label" align="left">Costo de expedici&oacute;n*</Text>
										<Text type="label" align="right">
											{Util.TRANSFORM.CURRENCY.MXN(paymentCost.expeditionRights)}
										</Text>
									</li>
								}

								{
									(paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code || ((paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.DEBIT.code) && (paymentType.id === QuoterPaymentFacade.PLAN_COST.ANNUAL.id))) &&
									<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
										<Text type="label" align="left">Responsabilidad Civil</Text>

										<Text type="label" align="right">
											{
												(paymentCost.monthsQuantity <= 1) && (paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) && (paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.DEBIT.code)
													? Util.TRANSFORM.CURRENCY.MXN(0)
													: paymentCost.getCivilTaxAmount()
											}
										</Text>
									</li>
								}

								{
									(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
									<li className="quoterPayment__mobileInfoSection__summary__list__monthlyItem">
										<div className="quoterPayment__mobileInfoSection__summary__list__monthlyItem__amount">
											<Text type="label" align="left">
												Pago
												{(paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code) ? ' diferido* ' : ' mensual '}
												({paymentMethod.id === QuoterPaymentFacade.PAYMENT_METHOD.CREDIT.code ? paymentCost.getFeePercent() : '20%'})
											</Text>
											<Text type="label" align="right">{getMonthFeeAmount()}</Text>
										</div>
									</li>
								}

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Subtotal</Text>
									<Text type="label" align="right">{getSubTotal()}</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">IVA (16%)</Text>
									<Text type="label" align="right">{getTaxes()}</Text>
								</li>

								{
									(paymentType.id !== QuoterPaymentFacade.PLAN_COST.ANNUAL.id) &&
									<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
										<Text type="label" align="left">Total por mes</Text>
										<Text type="label" align="right">{getTotalByMonth()}</Text>
									</li>
								}

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Total</Text>
									<Text type="label" align="right">{getTotal()}</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="center" className="quoterPayment__mobileInfoSection__summary__list__monthlyItem__warning">
										* Este monto no será reembolsado <br /> en caso de cancelación anticipada.
									</Text>
								</li>
							</ul>
						</>
					}

					{
						paymentType.id === QuoterPaymentFacade.PLAN_COST.SUSCRIPTION.id &&
						<>
							<Text className="quoterPayment__mobileInfoSection__summary__title" bold type="label" align="center">Resumen del primer pago</Text>
							<ul className="quoterPayment__mobileInfoSection__summary__list">
								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Prima neta</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getNetAmount() : storedPlan.subscription.firstCost.getNetAmount()}
									</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Responsabilidad civil</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getCivilTaxAmount() : storedPlan.subscription.firstCost.getCivilTaxAmount()}
									</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Pago diferido*</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getMonthFeeAmount() : storedPlan.subscription.firstCost.getMonthFeeAmount()}
									</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Costo de expedición*</Text>
									<Text type="label" align="right">
										{storedPlan.discount ?
											Util.TRANSFORM.CURRENCY.MXN(storedPlan.discount.subscription.firstCost.expeditionRights)
											:
											Util.TRANSFORM.CURRENCY.MXN(storedPlan.subscription.firstCost.expeditionRights)
										}
									</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Subtotal</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getSubTotal() : storedPlan.subscription.firstCost.getSubTotal()}
									</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">IVA</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTaxes() : storedPlan.subscription.firstCost.getTaxes()}
									</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left" bold>Total</Text>
									<Text type="label" align="right" bold>
										{storedPlan.discount ? storedPlan.discount.subscription.firstCost.getTotal() : storedPlan.subscription.firstCost.getTotal()}
									</Text>
								</li>
							</ul>

							<Text className="quoterPayment__mobileInfoSection__summary__title" bold type="label" align="center">Resumen de los siguientes 11 pagos</Text>
							<ul className="quoterPayment__mobileInfoSection__summary__list">
								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Prima neta</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.cost.getNetAmount() : storedPlan.subscription.cost.getNetAmount()}
									</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Pago diferido*</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.cost.getMonthFeeAmount() : storedPlan.subscription.cost.getMonthFeeAmount()}
									</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">Subtotal</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.cost.getSubTotal() : storedPlan.subscription.cost.getSubTotal()}
									</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left">IVA</Text>
									<Text type="label" align="right">
										{storedPlan.discount ? storedPlan.discount.subscription.cost.getTaxes() : storedPlan.subscription.cost.getTaxes()}
									</Text>
								</li>

								<li className="quoterPayment__mobileInfoSection__summary__list__listItem">
									<Text type="label" align="left" bold>Total</Text>
									<Text type="label" align="right" bold>
										{storedPlan.discount ? storedPlan.discount.subscription.cost.getTaxes() : storedPlan.subscription.cost.getTotal()}
									</Text>
								</li>
							</ul>
						</>
					}
				</section>

				<section className="quoterPayment__mobileInfoSection__bottomSection">
					<form>
						{
							urlId === PAYMENT_ROUTE_ID.METHOD &&
							<Controller
								name="acceptPaymentMethod"
								control={methodForm.control}
								rules={{ required: true }}
								render={({ field }) => (
									<Checkbox onChange={(e) => { field.onChange(e.target.checked) }} checked={methodForm.getValues('acceptPaymentMethod')}>
										<Text type="label" align="left">Estoy de acuerdo con la forma de pago seleccionada.</Text>
									</Checkbox>
								)}
							/>

						}

						{
							urlId === PAYMENT_ROUTE_ID.DATA &&
							<>
								<Controller
									name="acceptResourcesOwner"
									control={paymentForm.control}
									rules={{ required: true }}
									render={({ field }) => (
										<Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={paymentForm.getValues('acceptResourcesOwner')}>
											<Text type="label" align="left">Declaro que soy propietario de los recursos.</Text>
										</Checkbox>
									)}
								/>

								<Controller
									name="acceptDigitalCover"
									control={paymentForm.control}
									rules={{ required: true }}
									render={({ field }) => (
										<Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={paymentForm.getValues('acceptDigitalCover')}>
											<Text type="label" align="center" className="quoterPayment__mobileInfoSection__bottomSection__digitalCoverText">Estoy de acuerdo con recibir mi carátula de póliza en formato digital .PDF *</Text>
										</Checkbox>
									)}
								/>
							</>
						}
					</form>

					<Button type="button"
						disabled={
							(urlId === PAYMENT_ROUTE_ID.METHOD && !(methodForm.formState.isDirty && methodForm.formState.isValid))
							|| (urlId === PAYMENT_ROUTE_ID.DATA && (!(paymentForm.formState.isDirty && paymentForm.formState.isValid) || !isCardCompleted || isCompletingCheckout))
						}
						loading={isCompletingCheckout}
						onClick={proceed}
					>
						{urlId === PAYMENT_ROUTE_ID.METHOD ? 'Continuar' : 'Pagar'}
					</Button>
				</section>
			</aside>

			<Modal show={showRenewalModal} position="center" backgroundDismiss onClose={() => setShowRenewalModal(false)}>
				<div className="quoterPayment__renewalModal">
					{IconFactory.closeIcon({ onClick: () => setShowRenewalModal(false) })}
					<span className="quoterPayment__renewalModal__image" style={{ backgroundImage: `url(${Util.ASSET.INFO_IMAGE})` }}></span>
					<Text type="paragraph" align="center">Ayúdanos a saber qué tipo <br /> de experiencia prefieres</Text>

					<RadioGroup
						items={renewalItems}
						label
						onSelectItem={(item) => setIsAutomaticRenewal(item.id === renewalItems[0].id)}
					/>

					<div className="quoterPayment__renewalModal__buttonsWrapper">
						<Button
							type="button"
							className="block"
							disabled={isAutomaticRenewal === undefined}
							onClick={() => { setShowRenewalModal(false); setShowPicturesModal(true) }}
						>
							Continuar
						</Button>
						<a target="_blank" href="https://wa.me/5213321011715?text=Tengo%20una%20duda." rel="noreferrer"><Text type="label">Tengo dudas</Text> 💬</a>
					</div>
				</div>
			</Modal>

			<Modal show={showPicturesModal} position="center" onClose={() => setShowPicturesModal(false)}>
				<div className="quoterPayment__picturesModal">
					{IconFactory.closeIcon({ onClick: () => setShowPicturesModal(false) })}
					<div className="quoterPayment__picturesModal__picture" style={{ backgroundImage: `url(${Util.ASSET.POLICY_CAR_PICTURES})` }} />
					<Text align="center" type="label" className="quoterPayment__picturesModal__limitTimeText"><b>Tendrás hasta 3 días para enviarnos fotos de tu auto</b> y así evitar que el seguro sea desactivado.</Text>
					<Text align="center" type="label">Este paso es indispensable para poder darte una mejor experiencia al momento de un siniestro.</Text>
					<Button className="quoterPayment__picturesModal__button" onClick={() => { setShowPicturesModal(false); initCheckout() }}>Finalizar contratación</Button>
				</div>
			</Modal>

			<Modal show={isCompletingCheckout} position="center">
				<div className="quoterPayment__payingModal">
					<Text type="title" align="center">¡Un momento!</Text>
					<Spinner color="main" relative />
					<Text type="paragraph" align="center">Preparando tu seguro...</Text>
				</div>
			</Modal>
		</div >
	);
};

export default QuoterPaymentPage;
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentCost } from "src/classes/plan/PaymentCost";
import { PlanCheckout } from "src/classes/plan/PlanCheckout";
import { AlertFactory } from "src/factories/alert.factory";
import { TrackingFactory } from "src/factories/tracking.factory";
import { PAYMENT_ROUTE_ID, PROFILE_ROUTE_ID, QUOTER_PATH } from "src/routes/paths/quoter.paths";
import { Util } from "src/utils/Util";
import { QuoterAction } from "../actions/quoter.action";
import { QuoterPolicyFeature } from "../features/quoter-policy.feature";
import { QuoterParamsFacade } from "./quoter-params.facade";
import { QuoterFacade } from "./quoter.facade";

/**
 *
 */
export namespace QuoterPaymentFacade {

	/**
   * 
   */
	export const PAYMENT_METHOD = {
		DEBIT: { name: 'Tarjeta de Débito', code: 1 },
		CREDIT: { name: 'Tarjeta de Crédito', code: 2 }
	};
	
	export const PLAN_COST = {
		ANNUAL: { id: 1, name: 'Pago de contado' },
		SUSCRIPTION: { id: 2, name: 'Pago mes a mes' },
	};

	const renewalItems = [
		{ id: 0, value: 'Quiero que el pago de mi renovación sea automático.' },
		{ id: 1, value: 'Quiero realizar el pago de mi renovación manualmente.'}
	]

  /**
   * 
   */
  export const useQuoterPayment = () => {
		const { storedPlan, storedPolicy } = QuoterFacade.useQuoterActors();
		const { search } = QuoterParamsFacade.useQuoterParams();
		const [paymentMethod, setPaymentMethod] = useState<{ id: number, value: string }>(
			() => {
				let method = { id: PAYMENT_METHOD.CREDIT.code, value: PAYMENT_METHOD.CREDIT.name };
				if (storedPlan && storedPlan.selectedPlanPayment.selectedPaymentMethod === Util.CONSTANT.CONTRACT_TYPES.CARD.DEBIT) {
					method = { id: PAYMENT_METHOD.DEBIT.code, value: PAYMENT_METHOD.DEBIT.name };
				}
				return method;
			}
		);
		const [paymentType, setPaymentType] = useState<{ id: number, value: string }>(
			() => {
				let type = { id: PLAN_COST.ANNUAL.id, value: PLAN_COST.ANNUAL.name };
				if (storedPlan && storedPlan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.SUBSCRIPTION) {
					type = { id: PLAN_COST.SUSCRIPTION.id, value: PLAN_COST.SUSCRIPTION.name } 
				} else if (storedPlan && storedPlan.selectedPlanPayment.type === Util.CONSTANT.PLAN_PAYMENT_TYPES.INSTALLMENTS) {
					for (let idx = 0; idx < storedPlan.monthly.costs.length; idx ++) {
						if (storedPlan.selectedPlanPayment.paymentsCount === storedPlan.monthly.costs[idx].monthsQuantity) {
							type = { 
								id: (idx + 1) + PLAN_COST.SUSCRIPTION.id,
								value: `Pago a ${storedPlan.monthly.costs[idx].monthsQuantity} meses`,
							}
						}
					}
				}
				return type;
			}
		);
		const [paymentCost, setPaymentCost] = useState<PaymentCost>(() => {
			if (storedPlan) {
				return storedPlan.discount ? storedPlan.discount.cost : storedPlan.cost
			} else
				return new PaymentCost()
    });
		const [stripe, setStripe] = useState<Stripe | undefined>();
    const [stripeElement, setStripeElement] = useState<StripeElements | undefined>();
		const [planCheckout, setPlanCheckout] = useState<PlanCheckout>(() => {
      let checkout: PlanCheckout = new PlanCheckout();
			checkout.paymentId = storedPlan.selectedPlanPayment.id;
      return checkout;
    });
		const [isCardCompleted, setIsCardCompleted] = useState<boolean>(false);
    const [isCompletingCheckout, setIsCompletingCheckout] = useState<boolean>(false);
		const [showRenewalModal, setShowRenewalModal] = useState<boolean>(false);
		const [showPicturesModal, setShowPicturesModal] = useState<boolean>(false);
		const [isAutomaticRenewal, setIsAutomaticRenewal] = useState<boolean>();
		const { id: urlId } = useParams<string>();
		const navigate = useNavigate();
		const dispatch = useDispatch();

		/**
		 * 
		 */
		const proceed = () => {
			if (urlId === PAYMENT_ROUTE_ID.METHOD) {
				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.paymentMethod.name,
					[TrackingFactory.EVENTS.paymentMethod.properties.paymentType]: paymentMethod.value, // Esta al reves en GoogleAnalytics
					[TrackingFactory.EVENTS.paymentMethod.properties.paymentMethod]: paymentType.value, // Esta al reves en GoogleAnalytics
				});
				navigate(`${QUOTER_PATH.QUOTER_PAYMENT_PATH}/${PAYMENT_ROUTE_ID.DATA}${search}`);
			} else if (urlId === PAYMENT_ROUTE_ID.DATA) {
				setShowRenewalModal(true);
			}
		};

		/**
		 * 
		 */
		const initCheckout = async() => {
			try {
        setIsCompletingCheckout(true);
				TrackingFactory.Tracker.track({
					event: TrackingFactory.EVENTS.paymentData
				});
        const newPolicy = await QuoterPolicyFeature.policyPaymentFeature(planCheckout, {stripe: stripe!, elements: stripeElement!});
				if(isAutomaticRenewal !== undefined && storedPolicy) {
					await QuoterPolicyFeature.automaticRenewalFeature(storedPolicy, isAutomaticRenewal);
					
					if(newPolicy) {
						storedPolicy.policyNumber = newPolicy.policyNumber;
						dispatch(QuoterAction.setPolicy(storedPolicy));
					}
				}
				
				navigate(`${QUOTER_PATH.QUOTER_COMPLETION_PATH}${search}`)	
      } catch (e) {
        AlertFactory.errorAlert((e as Error).message);
      } finally {
        setIsCompletingCheckout(false);
      }
		}

		/**
		 * 
		 * @returns 
		 */
		const onBackRoute = (): string => {
			let _route = '';

			if (urlId === PAYMENT_ROUTE_ID.METHOD)
				_route = `${QUOTER_PATH.QUOTER_PROFILE_PATH}/${PROFILE_ROUTE_ID.RFC}${search}`
			else if (urlId === PAYMENT_ROUTE_ID.DATA)
				_route = `${QUOTER_PATH.QUOTER_PAYMENT_PATH}/${PAYMENT_ROUTE_ID.METHOD}${search}`

			return _route
		};

		/**
     * @param name 
     */
		const setCheckoutOwner = (name: string): void => {
			planCheckout.ownerName = name.trim();
			setPlanCheckout(planCheckout);
		}
		
		/**
		 * 
		 * @param type 
		 */
		const setCheckoutCard = (isCompleted: boolean, stripe?: Stripe, elements?: StripeElements, type?: string): void => {
			setIsCardCompleted(isCompleted);
			if (isCompleted) {
				planCheckout.cardType = type!;
				setStripe(stripe);
				setStripeElement(elements);
			} else {
				planCheckout.cardType = '';
			}
			setPlanCheckout(planCheckout);
		}

		/**
     * @param id 
     */
    const setCheckoutPaymentId = (id: string, cost: PaymentCost, isMonthly: boolean): void => {
      planCheckout.paymentId = id;
      planCheckout.cost = cost;
			planCheckout.isMonthly = isMonthly;
      setPaymentCost(cost);
      setPlanCheckout(planCheckout);
    }

		/** */
		const onPaymentTypeChange = (type: { id: number, value: string }) => {
			let paymentId: string = '';
			let paymentCost: PaymentCost = new PaymentCost();

			if (!storedPlan)
				return

			if (type.id === PLAN_COST.ANNUAL.id) {
				paymentCost = storedPlan.discount ? storedPlan.discount.cost : storedPlan.cost;

				for (const payment of storedPlan.payments) {
					if (payment.type === Util.STATUS_CODE.PLAN_TYPES.ANNUAL)
						paymentId = payment.id;
				}
			} else if (type.id === PLAN_COST.SUSCRIPTION.id) {
				paymentCost = storedPlan.subscription.firstCost;
				for (const payment of storedPlan.payments)
					if(payment.type === Util.STATUS_CODE.PLAN_TYPES.SUBSCRIPTION)
						paymentId = payment.id;
			} else {
				const months = type.value.match(/\d+/g);
				const monthsQuantity = months && parseInt(months[0]);

				if (monthsQuantity) {
					if (storedPlan.discount)
						paymentCost = storedPlan.discount.monthly.costs.find(p => p.monthsQuantity === monthsQuantity)! 
					else 
						paymentCost = storedPlan.monthly.costs.find(p => p.monthsQuantity === monthsQuantity)!
					
					for (const payment of storedPlan.payments)
						if (payment.type === Util.STATUS_CODE.PLAN_TYPES.INSTALLMENTS && payment.paymentsCount === monthsQuantity)
							paymentId = payment.id
				}
			}

			const isMonthly = type.id !== PLAN_COST.ANNUAL.id;
			setPaymentType(type);
			setCheckoutPaymentId(paymentId, paymentCost, isMonthly)
		}

		/**
		 * 
		 * @returns 
		 */
		const getTotal = (): string => {
			if (paymentType.id !== PLAN_COST.ANNUAL.id) {
				if (paymentMethod.id === PAYMENT_METHOD.CREDIT.code) {
					return paymentCost.monthsQuantity > 1 ? paymentCost.getTotal() : Util.TRANSFORM.CURRENCY.MXN(0);
				} else {
					return storedPlan.discount ? storedPlan.discount.subscription.getTotal() : storedPlan.subscription.getTotal();
				}
			} else {
				return paymentCost.getTotal();
			}
		};

		/**
		 * 
		 * @returns 
		 */
		const getTotalByMonth = (): string => {
			if (paymentMethod.id === PAYMENT_METHOD.CREDIT.code) {
				return paymentCost.monthsQuantity > 1 ? paymentCost.getAmountByMonth() : Util.TRANSFORM.CURRENCY.MXN(0);
			} else {
				return storedPlan.discount ? storedPlan.discount.subscription.cost.getTotal() : storedPlan.subscription.cost.getTotal();
			}
		};

		/**
		 * 
		 * @returns 
		 */
		const getNetAmount = (): string => {
			if (paymentMethod.id !== PAYMENT_METHOD.CREDIT.code && paymentType.id === PLAN_COST.SUSCRIPTION.id) {
				return storedPlan.discount ? storedPlan.discount.subscription.cost.getNetAmount() : storedPlan.subscription.cost.getNetAmount();
			} else {
				return paymentCost.getNetAmount();
			}
		}

		/**
		 * 
		 * @returns 
		 */
		const getMonthFeeAmount = (): string => {
			if (paymentMethod.id === PAYMENT_METHOD.CREDIT.code) {
				return paymentCost.monthsQuantity > 1 ? paymentCost.getMonthFeeAmount() : Util.TRANSFORM.CURRENCY.MXN(0)
			} else {
				return storedPlan.discount ? storedPlan.discount.subscription.cost.getMonthFeeAmount() : storedPlan.subscription.cost.getMonthFeeAmount();
			}
		}

		/**
		 * 
		 * @returns 
		 */
		const getSubTotal = (): string => {
			if (paymentCost.monthsQuantity <= 1 && (paymentType.id !== PLAN_COST.ANNUAL.id) && (paymentMethod.id === PAYMENT_METHOD.CREDIT.code)) {
				return Util.TRANSFORM.CURRENCY.MXN(0);
			} else if (paymentMethod.id === PAYMENT_METHOD.DEBIT.code && paymentType.id !== PLAN_COST.ANNUAL.id) {
				return storedPlan.discount ? storedPlan.discount.subscription.cost.getSubTotal() : storedPlan.subscription.cost.getSubTotal();
			} else {
				return paymentCost.getSubTotal();
			}
		}

		/**
		 * 
		 * @returns 
		 */
		const getTaxes = (): string => {
			if ((paymentCost.monthsQuantity <= 1) && paymentType.id !== PLAN_COST.ANNUAL.id && paymentMethod.id === PAYMENT_METHOD.CREDIT.code) {
				return Util.TRANSFORM.CURRENCY.MXN(0);
			} else if (paymentMethod.id === PAYMENT_METHOD.DEBIT.code && paymentType.id !== PLAN_COST.ANNUAL.id) {
				return storedPlan.discount ? storedPlan.discount.subscription.cost.getTaxes() : storedPlan.subscription.cost.getTaxes();
			} else {
				return paymentCost.getTaxes();
			}
		}

		/**
		 * 
		 */
		const onPaymentMethodChange = useCallback((method: { id: number, value: string }) => {
			if (method.id === paymentMethod.id)
				return;
			
			setPaymentMethod(method);
			setPaymentType({ id: PLAN_COST.ANNUAL.id, value: PLAN_COST.ANNUAL.name })
		}, [paymentMethod])

		useEffect(( ) => {
			if (showRenewalModal) 
				setIsAutomaticRenewal(undefined)
		}, [showRenewalModal])

		useEffect(( ) => {
			if (urlId === PAYMENT_ROUTE_ID.DATA) {
				setIsCardCompleted(false);
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [urlId])

		/**
		 * 
		 */
		useEffect(( ) => {
			onPaymentTypeChange(paymentType);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		return {
			search,
			PAYMENT_METHOD, 
			PLAN_COST,
			urlId,
			paymentMethod, 
			paymentType, 
			paymentCost,
			showRenewalModal,
			isAutomaticRenewal,
			renewalItems,
			showPicturesModal,
			isCardCompleted,
			isCompletingCheckout,
			onBackRoute,
			onPaymentMethodChange,
			onPaymentTypeChange,
			setCheckoutCard,
			setCheckoutOwner,
			setPaymentMethod,
			setShowRenewalModal,
			setShowPicturesModal,
			setIsAutomaticRenewal,
			proceed,
			getTotal,
			getTotalByMonth,
			getNetAmount,
			getMonthFeeAmount,
			getSubTotal,
			getTaxes,
			initCheckout
		}
  }

}